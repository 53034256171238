import React from 'react';
import { Typography, Box } from '@material-ui/core';
import theme from 'styles/theme';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand.js';
import { convertCurrency } from 'functions/convertNumber';
import { useGeneralContext } from 'context/GeneralContext';
import localStorageNames from 'data/localStorageNames';

const OrderedPrepItem = ({
  classes,
  getSellBandId,
  idItem,
  uuidProduct,
  timeStampProduct,
  prepItems,
  changePrepItemQuantity,
  changeRealmPrepItemQuantity,
  qtyProduct,
  isNormalOrder,
  isPaymentOrder,
  getColorApp,
}) => {
  const { getLocalStorage } = useGeneralContext();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  return prepItems.map((item, index) => (
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      style={{ marginBottom: theme.spacing(1) }}>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: 'inherit' }}>
        <Typography
          variant="caption"
          className={`${classes.modifierText} ${classes.textWrapper}`}
          style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 18 }}
        >
          <Box component="div" whiteSpace="normal">
            {`+ ${isNormalOrder
                ? item.quantity * qtyProduct
                : item.baseQuantity * qtyProduct
              } x ${item.plu_name}`}
          </Box>
        </Typography>
      </Box>
      <Box
        display="flex"
        alignSelf="center"
        justifyContent="flex-end"
        style={{ width: 165 }}>
        {isNormalOrder
          ? getPriceBySellBand(item, getSellBandId()) !== 0 && (
            <Typography variant="caption" className={classes.modifierText}
              style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 18 }}
            >
              <Box component="div" whiteSpace="normal">
                +
                {convertCurrency(
                  getPriceBySellBand(item, getSellBandId()) *
                  (item.quantity > 0 ? item.quantity : 1) *
                  (qtyProduct > 1 ? qtyProduct : 1),
                )}
              </Box>
            </Typography>
          )
          : item.amount > 0 && (
            <Typography variant="caption" className={classes.modifierText}
              style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 18 }}
            >
              <Box component="div" whiteSpace="normal">
                +
                {convertCurrency(
                  item.amount * (item.baseQuantity * qtyProduct),
                )}
              </Box>
            </Typography>
          )}
      </Box>
    </Box>
  ));
};

export default OrderedPrepItem;
