import { Box, ButtonBase, Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ReButtonQuantity from 'components/ReButtonQuantity';
import React from 'react';
import DetailOrder from './DetailOrder';
import { convertCurrency } from 'functions/convertNumber';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import theme from 'styles/theme';
import { useAuthContext } from 'context/AuthContext';
import { useGeneralContext } from 'context/GeneralContext';
import localStorageNames from 'data/localStorageNames';

const CartItem = ({
  classes,
  t,
  getSellBandId,
  data,
  changeItemQty,
  removeItem,
  changePrepItemQuantity,
  changeRealmPrepItemQuantity,
  isNormalOrder,
  isPaymentOrder,
  getColorApp,
}) => {
  const { getProductList } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const DetailOrderVariant = ({ item }) => {
    let prepGroup = [];

    const insertPrepItems = (data) => {
      return data[0]?.uuid_group ? data[0]?.prep_item : data;
    };

    if (isNormalOrder) {
      if (item.is_variant) {
        const variantChecked = item.variant_item.filter((data) => data.checked);
        if (variantChecked && variantChecked.length > 0) {
          if (
            variantChecked[0]?.prep_group &&
            variantChecked[0]?.prep_group.length > 0
          ) {
            prepGroup = insertPrepItems([...variantChecked[0]?.prep_group]);
          }
        }
      } else {
        if (item.prep_group && item.prep_group.length > 0) {
          prepGroup = insertPrepItems(item.prep_group);
        }
      }
    }

    if (isPaymentOrder) {
      let subProduct = [];

      getProductList().forEach((productList, index) => {
        if (
          Array.isArray(productList.categories) &&
          productList.categories.length > 0
        ) {
          productList.categories.forEach((categories, index) => {
            let productIndex = categories.products.findIndex(
              (product) => product.plu_number === item.plu_number,
            );

            if (productIndex !== -1) {
              subProduct = categories.products[productIndex].sub_product || [];
            }
          });
        } else {
          let productIndex = productList.products.findIndex(
            (product) => product.plu_number === item.plu_number,
          );

          if (productIndex !== -1) {
            const selectedProduct =
              getProductList()[index].products[productIndex] || [];
            subProduct = selectedProduct.sub_product;
          }
        }
      });

      prepGroup = insertPrepItems(item.prep_items || []);
      item.additional_request = item.note || [];
      item.sub_product = subProduct || []; // jika tipe paket ada sub product
    }

    return (
      <Grid item>
        <DetailOrder
          classes={classes}
          t={t}
          getSellBandId={getSellBandId || null}
          idItem={item._id || null}
          uuidProduct={item.uuid_product || null}
          timeStampProduct={item.timestamp || null}
          prepGroup={prepGroup || []}
          additionalRequest={item.additional_request || []}
          subProduct={item.sub_product || []}
          changePrepItemQuantity={changePrepItemQuantity}
          changeRealmPrepItemQuantity={changeRealmPrepItemQuantity}
          qtyProduct={item.quantity}
          isVariant={item.is_variant}
          isNormalOrder={isNormalOrder}
          isPaymentOrder={isPaymentOrder}
          getColorApp={getColorApp}
        />
      </Grid>
    );
  };

  const getItemName = (item) => {
    if (item.is_variant) {
      const variantChecked = item.variant_item.filter((data) => data.checked);
      if (variantChecked && variantChecked.length > 0) {
        return variantChecked[0]?.plu_name;
      }
    } else {
      return item.plu_name;
    }
  };

  return (
    <Grid container direction="column" className={`${classes.borderingGrid}`}>
      {Array.isArray(data) &&
        data.map((item, index) => (
          <Box key={index} className={`${classes.cartItem}`}>
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Grid item style={{ width: '65%' }}>
                <Typography
                  variant="body2"
                  className={'clampOnLine2'}
                  style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 22 }}>
                  {getItemName(item)}
                </Typography>
              </Grid>
              <Grid item style={{ width: '35%', textAlign: 'right' }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 22}}>
                  {isNormalOrder &&
                    convertCurrency(
                      getPriceBySellBand(item, getSellBandId()) * item.quantity,
                    )}
                  {isPaymentOrder &&
                    convertCurrency(item.amount * item.quantity)}
                </Typography>
              </Grid>
            </Grid>

            <DetailOrderVariant item={item} />

            <Grid container direction="row" className={classes.buttonQtyCart}>
              <Grid item>
                <ReButtonQuantity
                  onChangeValue={(value) => changeItemQty(item, value)}
                  propsValue={item.quantity}
                />
              </Grid>
              <Grid item>
                <ButtonBase onClick={() => removeItem(item)}>
                  <Delete
                    style={{ fontSize: isKiosK === '1' ? 30 : 20, color: theme.palette.error.main }}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </Box>
        ))}
    </Grid>
  );
};

export default CartItem;
