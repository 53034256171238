import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import getSearchParamUrl from 'functions/getSearchParamUrl';

let primaryThemeColor = getSearchParamUrl(window.location, 'primary_theme');
let secondaryThemeColor = getSearchParamUrl(window.location, 'secondary_theme');
let primaryFontColor = getSearchParamUrl(window.location, 'primary_text');
let secondaryFontColor = getSearchParamUrl(window.location, 'secondary_text');
let backgroundColor = getSearchParamUrl(window.location, 'background');

let theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryThemeColor ? primaryThemeColor : '#212121',
    },
    secondary: {
      main: secondaryThemeColor ? secondaryThemeColor : '#757575',
    },
    error: {
      main: '#B00020',
    },
    warning: {
      main: '#FF9100',
    },
    info: {
      main: '#0096C7',
    },
    success: {
      main: '#02A328',
    },
    text: {
      primary: primaryFontColor ? primaryFontColor : '#000000',
      secondary: secondaryFontColor ? secondaryFontColor : '#ffffff',
      disabled: '#C7C7C7',
    },
  },
  typography: {
    // fontFamily: ['"Helvetica Neue"'].join(','),
    fontSize: 14,
  },
  spacing: 4,
  headerHeight: 60,
  headerFontSize: 24,
  bodyFontSize: 22,
  backgroundColor: backgroundColor ? backgroundColor : '#C7C7C7',
  siteBackgroundColor: backgroundColor ? backgroundColor : '#C7C7C7',
  containerWidth: 450,
});

theme = responsiveFontSizes(theme);

export default theme;
