import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import { useCartContext } from 'context/CartContext';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

const useStyles = makeStyles({
  paper: {
    zIndex: `1310 !important`,
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1299,
  },
});

const ToggleLang = () => {
  const { i18n } = useTranslation();

  const handleChangeLang = (e, value) => {
    i18n.changeLanguage(value);
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={i18n.language}
      exclusive
      onChange={handleChangeLang}>
      <ToggleButton value="id">ID</ToggleButton>
      <ToggleButton value="en">EN</ToggleButton>
    </ToggleButtonGroup>
  );
};

const ToggleOpen = () => {
  const { isOpen, setOpen } = useAuthContext();
  const [stateOpen, setStateOpen] = useState(isOpen());

  const handleChangeLang = (e, value) => {
    setStateOpen(JSON.parse(value));
    setOpen(JSON.parse(value));
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={stateOpen.toString()}
      exclusive
      onChange={handleChangeLang}>
      <ToggleButton value="true">True</ToggleButton>
      <ToggleButton value="false">False</ToggleButton>
    </ToggleButtonGroup>
  );
};

const ToggleViewBillSetting = () => {
  const { getOrderMethod, setOrderMethod } = useAuthContext();
  const [viewBill, setViewBill] = useState(getOrderMethod());

  const handleChange = (e) => {
    setOrderMethod(e.currentTarget.value);
    setViewBill(e.currentTarget.value);
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={viewBill.toString()}
      exclusive
      onChange={handleChange}>
      <ToggleButton value="normal_order">Table</ToggleButton>
      <ToggleButton value="payment_order">Personal</ToggleButton>
    </ToggleButtonGroup>
  );
};

const DevelopmentPanel = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [mockQty, setMockQty] = useState(1);
  const { clearAllStorage, getProductList } = useAuthContext();
  const { addToCart, handleOpenCheckoutPage } = useCartContext();

  const handleMockOrder = () => {
    const getRandomNumber = (max) => {
      return Math.floor(Math.random() * Math.floor(max));
    };

    let selectedProducts = [];
    for (let i = 0; i < mockQty; i++) {
      let mockedProductCategory =
        getProductList()[getRandomNumber(getProductList().length)];
      let mockedProduct =
        mockedProductCategory.products[
          getRandomNumber(mockedProductCategory.products.length)
        ];

      mockedProduct.quantity = getRandomNumber(4) || 1;
      mockedProduct.additional_request = `Mock description (${i + 1})`;
      mockedProduct.timestamp = new Date().getTime() + getRandomNumber(3);

      if (mockedProduct.prep_group.length > 0) {
        mockedProduct.prep_group.map((group, groupIndex) => {
          group.prep_item.map((item, itemIndex) => {
            if (itemIndex < group.choose.max) {
              mockedProduct.prep_group[groupIndex].prep_item[
                itemIndex
              ].checked = true;
            }
            return '';
          });
          return '';
        });
      }

      selectedProducts.push(mockedProduct);
    }

    // MASUK KAN DATA KECART SATU PER SATU DENGAN DELAY 2 DETIK, KARENA LOCAL STORAGE GAK BISA SECEPAT MILI SECOND
    selectedProducts.forEach((item, index) => {
      setTimeout(() => {
        addToCart(item, true);
      }, index * 2000);
    });

    handleOpenCheckoutPage();
  };

  return (
    <div
      className={classes.container}
      onClick={() => {
        setShowModal(true);
      }}>
      {/* <button type="button">Dev Panel</button> */}
      {showModal && (
        <ReDialog
          title="DEVELOPMENT PANEL"
          dialogStyle={classes.paper}
          closeModal={() => {
            setShowModal(false);
          }}
          content={
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>
                  {`login time :
                    ${moment(
                      +JSON.parse(localStorage.getItem('creds'))['login-stamp'],
                    ).format('DD/MM/YYYY HH:mm:ss')}`}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>Language</Grid>
                <Grid item>
                  <ToggleLang />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>isOpen</Grid>
                <Grid item>
                  <ToggleOpen />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>ViewBill Setting</Grid>
                <Grid item>
                  <ToggleViewBillSetting />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>Mock Order</Grid>
                <Grid item>
                  <input
                    type="number"
                    style={{ width: 50 }}
                    value={mockQty}
                    onChange={(event) => {
                      setMockQty(
                        event.target.value > 10 ? 10 : event.target.value,
                      );
                    }}
                  />

                  <button
                    onClick={() => {
                      handleMockOrder();
                    }}>
                    ORDER
                  </button>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between', marginBottom: 10 }}>
                <Grid item>NoClearToken</Grid>
                <Grid item>
                  <button
                    onClick={() => {
                      clearAllStorage('partial');
                      history.replace('/login');
                    }}>
                    Logout
                  </button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </div>
  );
};

export default DevelopmentPanel;
