import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ButtonGroup, Button } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons';
import { useAuthContext } from 'context/AuthContext';
import { useGeneralContext } from 'context/GeneralContext';
import theme from 'styles/theme';
import localStorageNames from 'data/localStorageNames';

const useStyles = makeStyles({
  buttonQty: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

const ReButtonQuantity = ({
  onChangeValue = () => {},
  propsValue,
  max,
  qtyProduct,
}) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();
  const [value, setValue] = useState(propsValue || 1);
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const handleIncreaseQty = () => {
    setValue(value + 1);
    onChangeValue(value + 1);
  };

  const handleDecreaseQty = () => {
    setValue(value - 1);
    onChangeValue(value - 1);
  };

  useEffect(() => {
    setValue(propsValue || 1);
  }, [propsValue]);

  return (
    <Box className={classes.buttonQty}>
      <ButtonGroup
        size="small"
        color="primary"
        variant="contained"
        style={{ height: isKiosK === '1' ? 40 : 25 }}>
        {/* Button Material UI */}
        <Button
          onClick={handleDecreaseQty}
          disabled={value === 1}
          style={{
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            backgroundColor:
              value === 1 ? 'lightgray' : getColorApp()?.secondaryThemeColor,
          }}>
          <Remove
            style={{
              fontSize: isKiosK === '1' ? theme.bodyFontSize : 16,
              color: getColorApp()?.primaryFontColor,
            }}
          />
        </Button>
        <Button
          disabled
          style={{
            color: getColorApp()?.secondaryFontColor,
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            backgroundColor: getColorApp()?.primaryThemeColor,
          }}>
          <Box fontSize={isKiosK === '1' ? theme.bodyFontSize : 16}>
            {value * (qtyProduct > 1 ? qtyProduct : 1)}
          </Box>
        </Button>
        <Button
          onClick={handleIncreaseQty}
          disabled={value > max}
          style={{
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            backgroundColor:
              value > max ? 'lightgray' : getColorApp()?.secondaryThemeColor,
          }}>
          <Add
            style={{
              fontSize: isKiosK === '1' ? theme.bodyFontSize : 16,
              color: getColorApp()?.primaryFontColor,
            }}
          />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ReButtonQuantity;
