import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import theme from 'styles/theme';
import localStorageNames from 'data/localStorageNames';
import { useGeneralContext } from 'context/GeneralContext';

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const RePackageItem = ({ subProduct }) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();

  const { t } = useTranslation();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  return (
    <Grid>
      <Accordion expanded={true}>
        <AccordionSummary
          classes={{
            root: classes.headingTitle,
            content: classes.headingContent,
          }}
          style={{
            backgroundColor: getColorApp()?.accordionSuccessColor,
            color: getColorApp()?.secondaryFontColor,
          }}>
          <Box
            fontSize={
              isKiosK === '1' ? theme.bodyFontSize : theme.typography.fontSize
            }
            className={classes.textWrapper}>
            {t('detailPackage')}
          </Box>
        </AccordionSummary>
        {Array.isArray(subProduct) &&
          subProduct.map((product) => (
            <AccordionDetails
              key={product.uuid_product}
              style={{
                backgroundColor: getColorApp()?.backgroundColor,
                color: getColorApp()?.primaryFontColor,
              }}>
              <Grid container direction="row" justify={'space-between'}>
                <Grid item>
                  <Box
                    fontSize={
                      isKiosK === '1'
                        ? theme.bodyFontSize
                        : theme.typography.fontSize
                    }>
                    {`${product.plu_name}`}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    fontSize={
                      isKiosK === '1'
                        ? theme.bodyFontSize
                        : theme.typography.fontSize
                    }>
                    {`x ${product.quantity}`}
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          ))}
      </Accordion>
    </Grid>
  );
};

export default RePackageItem;
