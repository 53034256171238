import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
  Badge,
  useMediaQuery,
} from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { convertCurrency } from 'functions/convertNumber';
import { useGeneralContext } from 'context/GeneralContext';
import theme from 'styles/theme';
import HomeIcon from '@material-ui/icons/Home';
import { useAuthContext } from 'context/AuthContext';
import localStorageNames from 'data/localStorageNames';

const useStyles = makeStyles({
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0
  },
  button: {
    textTransform: 'none',
    width: '100%',
  },
  badge: {
    height: 15,
    minWidth: 15,
    fontSize: 10,
    lineHeight: 0.3,
    padding: '0 4px',
    backgroundColor: theme.palette.success.main,
  },
  typeSales: {
    paddingRight: theme.spacing(2),
  },
  innerButton: {
    justifyContent: 'space-between',
  },
  endIconStyle: {
    alignItems: 'center',
  },
});

const ReFloatingStaticButton = ({
  totalPrice,
  badgeCount,
  onClick,
  onClickBtnTenant,
}) => {
  const classes = useStyles();
  const { getTypeSalesName, getLocalStorage } = useGeneralContext();
  const { getColorApp } = useAuthContext();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);
  const bigSizeScreen = useMediaQuery('(min-width:600px)');

  return (
    <Grid
      container
      className={`${classes.buttonContainer}`}
      style={{
        backgroundColor: getColorApp()?.backgroundColor,
        padding: theme.spacing(2),
        paddingLeft: isKiosK === '1' ? theme.spacing(5) : theme.spacing(2),
        paddingRight: isKiosK === '1' ? theme.spacing(5) : theme.spacing(2),
        maxWidth: isKiosK !== '1' && theme.containerWidth,
        left:  (isKiosK !== '1' && bigSizeScreen) && `calc(50% - (${theme.containerWidth}px/2))`,
      }}>
      <Grid item xs={4} style={{ paddingRight: theme.spacing(2) }}>
        {/* Button Material UI */}
        <Button
          onClick={onClickBtnTenant}
          variant="contained"
          size="large"
          startIcon={
            <HomeIcon
              style={{
                color: getColorApp()?.primaryFontColor,
                fontSize: isKiosK === '1' ? 32 : 22,
              }}
            />
          }
          style={{
            height: isKiosK === '1' ? 60 : 50,
            width: '100%',
            backgroundColor: getColorApp()?.secondaryThemeColor,
            padding: 0,
          }}>
          <Typography
            style={{
              color: getColorApp()?.primaryFontColor,
              fontSize:
                isKiosK === '1'
                  ? theme.bodyFontSize
                  : theme.typography.fontSize,
            }}>
            Tenant
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={8}>
        {/* Button Material UI */}
        <Button
          className={classes.button}
          classes={{
            label: classes.innerButton,
            endIcon: classes.endIconStyle,
          }}
          style={{
            backgroundColor: getColorApp()?.secondaryThemeColor,
            height: isKiosK === '1' ? 60 : 50,
          }}
          variant="contained"
          size="large"
          onClick={badgeCount > 0 && onClick}
          endIcon={
            <>
              <Typography
                variant="caption"
                className={classes.typeSales}
                style={{
                  color: getColorApp()?.primaryFontColor,
                  fontSize:
                    isKiosK === '1'
                      ? theme.bodyFontSize
                      : theme.typography.fontSize,
                }}>
                {getTypeSalesName()}
              </Typography>
              <Badge
                badgeContent={badgeCount}
                classes={{ badge: classes.badge }}>
                <ShoppingCart
                  style={{
                    fontSize: isKiosK === '1' ? 32 : 22,
                    color: getColorApp()?.primaryFontColor,
                  }}
                />
              </Badge>
            </>
          }>
          <Typography
            style={{
              color: getColorApp()?.primaryFontColor,
              fontSize: isKiosK === '1' ? 24 : theme.typography.fontSize,
            }}>
            {convertCurrency(totalPrice)}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReFloatingStaticButton;
