import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CartProvider from 'context/CartContext';
import RealmProvider from 'context/RealmContext';
import getSearchParamUrl from 'functions/getSearchParamUrl';
import localStorageNames from 'data/localStorageNames';
import ToggleDrawer from '../pages/home/ToggleDrawer';
import ReOutletClosed from 'components/ReOutletClosed';
import DevelopmentPanel from 'components/DevelopmentPanel';
import moment from 'moment-timezone';
import getDiffDate from 'functions/getDiffDate';
import { useGeneralContext } from './GeneralContext';
import { useTranslation } from 'react-i18next';
import { Snackbar, useMediaQuery, Button } from '@material-ui/core';
import PouchDB from 'pouchdb';
import { useIdleTimer } from 'react-idle-timer'
import * as serviceWorker from '../serviceWorker';

const CONFIG_ENDPOINT =
  process.env[`REACT_APP_CONFIG_ENDPOINT_${process.env.REACT_APP_ENV}`];

const TENANTS_ENDPOINT =
  process.env[`REACT_APP_TENANTS_ENDPOINT_${process.env.REACT_APP_ENV}`];

const PRODUCT_ENDPOINT =
  process.env[`REACT_APP_PRODUCT_ENDPOINT_${process.env.REACT_APP_ENV}`];

const FEEDBACK_ENDPOINT =
  process.env[`REACT_APP_FEEDBACK_${process.env.REACT_APP_ENV}`];

const SUGGESTION_ENDPOINT =
  process.env[`REACT_APP_SUGGESTION_ENDPOINT_${process.env.REACT_APP_ENV}`];

const STRIPE_PAYMENT_ENDPOINT =
  process.env[`REACT_APP_STRIPE_PAYMENT_ENDPOINT_${process.env.REACT_APP_ENV}`];

const CATEGORY_ENDPOINT =
  process.env[`REACT_APP_CATEGORY_ENDPOINT_${process.env.REACT_APP_ENV}`];

const PRODUCT_TENANT_ENDPOINT =
  process.env[`REACT_APP_PRODUCT_TENANT_ENDPOINT_${process.env.REACT_APP_ENV}`];

const PRODUCT_DETAIL_ENDPOINT =
  process.env[`REACT_APP_DETAIL_PRODUCT_ENDPOINT_${process.env.REACT_APP_ENV}`];

export const AuthContext = React.createContext([{}, () => {}]);

export const clearAllStorage = (param) => {
  if (param === 'partial') {
    Object.values(localStorageNames).forEach((LSname) => {
      if (
        LSname === localStorageNames.CART_ITEMS ||
        LSname === localStorageNames.FILLED_FEEDBACK ||
        LSname === localStorageNames.USER
      ) {
        localStorage.removeItem(LSname);
      }
    });
  } else {
    Object.values(localStorageNames).forEach((LSname) => {
      if (
        LSname !== localStorageNames.TRANSACTIONS_ID ||
        LSname !== localStorageNames.KIOSK
      ) {
        localStorage.removeItem(LSname);
      }
    });
  }
};

let willMount = true;
let tempCred = {};
let splashUrl = '';
let logoUrl = '';

let isLoadingConfig = true;
let urlParams = '';

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const {
    setUserLocalStorage,
    toastError,
    setOrderCategoryLocalStorage,
    setLocalStorage,
    getLocalStorage,
  } = useGeneralContext();
  const { t } = useTranslation();
  const [state, setState] = useState({
    showClosedModal: false,
    loadingModal: false,
    feedbackList: [],
    feedbackResult: {
      error: false,
      message: '',
    },
    suggestion: {},
    clientSecreet: '',
    cancelLoadPage: false,
    productList: [],
    productTenant: {},
    productsTenant: [],
    categoryList: [],
    categoryForTenant: {
      category: {},
      index: 0,
    },
    detailProductTenant: null,
    uuidProductCategory: null,
    showReload: false,
    waitingWorker: null
  });
  const db = new PouchDB('foodcourt');

  const deleteDb = async () => {
    try {
      await db.destroy();
    } catch (err) {
      if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
        console.log('error ', err);
      }
    }
  };

  const setAuthState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getAuthState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setCredential = (data) => {
    tempCred = JSON.parse(data);
    setLocalStorage(localStorageNames.CREDENTIAL, data);
  };

  const getCredential = () => {
    let validCred = {};
    let credential =
      JSON.parse(getLocalStorage(localStorageNames.CREDENTIAL)) || {};

    if (
      credential.token !== null &&
      credential.token !== '' &&
      credential.uuid !== null &&
      credential.uuid !== ''
    ) {
      validCred = credential;
    } else if (
      tempCred.token !== null &&
      tempCred.token !== '' &&
      tempCred.uuid !== null &&
      tempCred.uuid !== ''
    ) {
      validCred = tempCred;
    } else {
      if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
        console.log('Dont have credential');
      }
    }

    return validCred;
  };

  const setClosedModal = (value) => {
    setAuthState({
      showClosedModal: value,
    });
  };

  const getColorApp = () => {
    const themeColor = JSON.parse(
      localStorage.getItem(localStorageNames.THEME_COLOR),
    );
    return themeColor;
  };

  const setColorApp = (data) => {
    let color = {
      primaryThemeColor: data.primary_color,
      secondaryThemeColor: data.secondary_color,
      primaryFontColor: data.primary_font_color,
      secondaryFontColor: data.secondary_font_color,
      backgroundColor: data.background_color,
      backgroundProduct: data.product_background_color,
      accordionHeaderColor: data.header_color,
      accordionSuccessColor: data.success_color,
      buttonAddToCartColor: data.add_to_cart_color,
    };
    setLocalStorage(localStorageNames.THEME_COLOR, JSON.stringify(color));
  };

  const getSetting = () => {
    return JSON.parse(getLocalStorage(localStorageNames.SETTING));
  };

  const setSetting = (data) => {
    let setting = {
      isOrderable: data.is_orderable,
      isDisplayCategory: data.display_category,
      isDisplaySearch: data.display_search,
      isDirectOrder: data.direct_order,
      isImageDetail: data.image_detail,
      isDisplayLogin: data.display_login,
      orderMethod: data.order_method || 'normal_order', // normal_order || payment_order || waiter_order || quick_order || tenant_order
      soldOutText: data.sold_out_text,
      confirmOrderText: data.confirm_order_text || '',
      isStore: data.is_store, // untuk mendeteksi qrcode dari qr store apa qr table
      placeholderParameter: data.placeholder_parameter,
      isQRStoreParameter: data.qrstore_parameter,
      tableName: data.tableName,
      subFunctionID: data.sub_function_id_stripe,
      qrcodeTitlePage: data.qrcode_title_page,
      tenantId: data.tenantID,
      tenantName: data.tenantName,
      feedbackText: data.feedback_text,
    };
    setLocalStorage(localStorageNames.SETTING, JSON.stringify(setting));
  };

  const getPaymentMethod = () => {
    return JSON.parse(getLocalStorage(localStorageNames.PAYMENT_METHOD));
  };

  const setPaymentMethod = (data) => {
    setLocalStorage(
      localStorageNames.PAYMENT_METHOD,
      JSON.stringify(data.payment_channels),
    );
  };

  const setLoadingModal = (value) => {
    setAuthState({
      loadingModal: value,
    });
  };

  const setOutletInfo = (key, value) => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo);
    outletInfo[key] = value;
    setLocalStorage(localStorageNames.OUTLET, JSON.stringify(outletInfo));
  };

  const setOpen = (value) => {
    setOutletInfo('openingHours', value);
  };

  const setFeedbackList = (data) => {
    setAuthState({
      feedbackList: data,
    });
  };

  const setResultFeedback = (isError = false, message) => {
    setAuthState({
      feedbackResult: {
        error: isError,
        message: message,
      },
    });
  };

  const setOutletInfoLocalStorage = (data) => {
    let information = {
      outletId: data.storeUUID,
      openingHours: data.openingHours,
      outletName: data.restaurantName,
      tableId: getCredential().table || getCredential().uuid,
      tableName: data.tableName,
      salestype: data.salestype,
      currency: data.currency,
      currencySymbol: data.currency_symbol,
      localeCode: data.locale_code,
    };
    setLocalStorage(localStorageNames.OUTLET, JSON.stringify(information));
  };

  const setTenantsLocalStorage = (data) => {
    setLocalStorage(localStorageNames.TENANTS, JSON.stringify(data));
  };

  const setProductLocalStorage = (data) => {
    let products = data.result;
    setLocalStorage(localStorageNames.PRODUCTS, JSON.stringify(products));
  };

  const getOutletInfo = () => {
    return getLocalStorage(localStorageNames.OUTLET);
  };

  const getTenantList = () => {
    return JSON.parse(getLocalStorage(localStorageNames.TENANTS));
  };

  const getProductList = () => {
    return JSON.parse(getLocalStorage(localStorageNames.PRODUCTS));
  };

  const getProductByCategory = () => {
    return JSON.parse(getLocalStorage(localStorageNames.PRODUCTS_BY_CATEGORY));
  };

  const getCategoryList = () => {
    const products = getProductList();
    let data =
      Array.isArray(products) &&
      products.map((newData) => {
        return { ...newData, isShow: false };
      });

    return data;
  };

  const getFeedbackList = () => {
    return JSON.parse(getLocalStorage(localStorageNames.FEEDBACK));
  };

  const getOutletName = () => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo) || {};
    return outletInfo.outletName || '';
  };

  const getOutletId = () => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo) || {};
    return outletInfo.outletId || '';
  };

  const getTableName = () => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo) || {};
    return outletInfo.tableName || '';
  };

  const getUserPhone = () => {
    let userInfo = getLocalStorage(localStorageNames.USER);
    if (userInfo === 'guest') {
      return '';
    } else {
      return userInfo;
    }
  };

  const getSalesType = () => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo) || {};
    return outletInfo.salestype || [];
  };

  const getActiveSalesType = () => {
    const salesType = getSalesType().sales;
    const sales = salesType.filter((sales) => sales.sequence > 0);
    return sales[0];
  };

  const getCurrentSalesType = () => {
    return JSON.parse(getLocalStorage(localStorageNames.ORDERCATEGORY));
  };

  const getOrderMethod = () => {
    let settings = getSetting() || {};
    return settings['orderMethod'] || 'normal_order';
  };

  const getSplashUrl = () => {
    return splashUrl;
  };

  const getLogoUrl = () => {
    return logoUrl;
  };

  const isOpen = () => {
    let outletInfo = getOutletInfo();
    outletInfo = JSON.parse(outletInfo) || {};
    if (
      outletInfo.openingHours === false &&
      getAuthState('showClosedModal') === false
    ) {
      setClosedModal(!outletInfo.openingHours);
    }
    return outletInfo.openingHours;
  };

  const isTokenExpired = (timestamp) => {
    if (getLocalStorage(localStorageNames.KIOSK) === '1') {
      return false;
    } else {
      let diffMinutes = getDiffDate(timestamp);
      // TOKEN EXPIRED FRONTEND DISET 30 MENIT, KETIKA LEBIH DARI ITU, HARUS CLEAR STORAGE
      if (diffMinutes > 30) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isAuthenticated = () => {
    let credential = getCredential();
    if (
      credential.token !== null &&
      credential.token !== '' &&
      credential.table !== null &&
      credential.table !== ''
    ) {
      if (isTokenExpired(+credential['login-stamp'])) {
        // JIKA SESSION LEBIH DARI 30 MENIT
        history.replace('/session-expired');
      } else {
        setTimeout(() => {
          if (!getOutletInfo()) {
            fetchAppsConfig(credential);
          }
        }, 100);
      }
    } else {
      // JIKA TIDAK ADA TOKEN
      history.replace('/invalid-token');
    }
  };

  const saveImage = () => {
    let splash = getSearchParamUrl(history.location, 'splash') || '';
    let logo = getSearchParamUrl(history.location, 'logo') || '';
    let decodedSplash = decodeURIComponent(splash);
    let decodedLogo = decodeURIComponent(logo);
    splashUrl = decodedSplash;
    logoUrl = decodedLogo;
    setLocalStorage(localStorageNames.SPLASH, decodedSplash);
    setLocalStorage(localStorageNames.LOGO, decodedLogo);
  };

  const dumpToken = () => {
    let tokenId = getSearchParamUrl(history.location, 'token') || '';
    let uuid = getSearchParamUrl(history.location, 'uuid') || '';
    let tableId = getSearchParamUrl(history.location, 'table') || '';
    setLocalStorage(
      localStorageNames.KIOSK,
      getSearchParamUrl(history.location, 'kiosk') || '0',
    );
    if (tokenId) {
      // LOGIN TIMESTAMP DIGUNAKAN UNTUK CLEARING STORAGE (EXPIRED TOKEN) KETIKA MELEBIHI KETENTUAN
      let loginStamp = moment(new Date()).format('x');
      let newCredential = {
        'login-stamp': loginStamp,
        token: tokenId,
      };

      if (tableId) {
        newCredential.table = tableId;
      } else {
        newCredential.uuid = uuid;
      }
      setCredential(JSON.stringify(newCredential));
    }
  };

  const errorMessages = (errorMessages) => {
    let messages = '';
    if (errorMessages && Array.isArray(errorMessages)) {
      errorMessages.map((data) => {
        messages += data + '\n';
        return messages;
      });
    }
    return messages;
  };

  const hitAPI = async (url, method, credential, data) => {
    try {
      const feedback = await fetch(url, {
        method: method || 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'qrcode-token': credential.token,
          'qrcode-uuid': credential.uuid || credential.table,
        },
        body: JSON.stringify(data),
      });
      let response = await feedback.json();
      response.status = feedback.status;
      return response;
    } catch (e) {
      history.replace('/invalid-token');
    }
  };

  const fetchAppsConfig = (credential) => {
    if (isLoadingConfig) {
      isLoadingConfig = false;
      hitAPI(CONFIG_ENDPOINT, 'GET', credential).then((response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            setOutletInfoLocalStorage(response.result);
            setSetting(response.result);
            setColorApp(response.result);
            setPaymentMethod(response.result);
            setTimeout(() => {
              if (!getCredential().table && !getCredential().uuid) {
                history.replace('/session-expired');
              } else {
                if (getOrderMethod() === 'tenant_order') {
                  fetchTenants(credential);
                } else {
                  fetchProducts(credential);
                }
              }
            }, 500);
          } else {
            history.replace('/invalid-token');
          }
        } else {
          history.replace('/session-expired');
        }
      });
    }
  };

  const fetchTenants = (credential) => {
    hitAPI(TENANTS_ENDPOINT, 'POST', credential).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.status === 200) {
          setTenantsLocalStorage(response.result);
          isOpen();
          setTimeout(() => {
            if (getSetting() && getSetting().isDisplayLogin) {
              history.replace('/login');
            } else {
              setUserLocalStorage('guest');
              if (getSalesType().active === 1) {
                let localType = getActiveSalesType();
                // KARENA IKON BELUM PERLU UNTUK DISIMPAN
                delete localType.icon;
                setOrderCategoryLocalStorage(JSON.stringify(localType));
                if (getSetting() && getSetting().tenantId) {
                  let tenant = {
                    tenant_token: '',
                    tenant_name: getSetting().tenantName,
                    uuid_tenant: getSetting().tenantId,
                    photo: '',
                  };
                  localStorage.setItem(
                    localStorageNames.TENANT_ID,
                    JSON.stringify(tenant),
                  );
                  history.push('/menu-catalogue');
                } else {
                  history.push('/tenant-page');
                }
              } else {
                history.replace('/order-category');
              }
            }
          }, 100);
        } else {
          let messages = errorMessages(response.messages);
          history.replace('/error-page', messages);
        }
      } else {
        history.replace('/session-expired');
      }
    });
  };

  const fetchProducts = (credential) => {
    hitAPI(PRODUCT_ENDPOINT, 'POST', credential).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.status === 200) {
          setProductLocalStorage(response);
          isOpen();
          setTimeout(() => {
            if (getSetting() && getSetting().isDisplayLogin) {
              history.replace('/login');
            } else {
              setUserLocalStorage('guest');
              if (getSalesType().active === 1) {
                let localType = getActiveSalesType();
                // KARENA IKON BELUM PERLU UNTUK DISIMPAN
                delete localType.icon;
                setOrderCategoryLocalStorage(JSON.stringify(localType));
                history.replace('/menu-catalogue');
              } else {
                history.replace('/order-category');
              }
            }
          }, 100);
        } else {
          let messages = errorMessages(response.messages);
          history.replace('/error-page', messages);
        }
      } else {
        history.replace('/session-expired');
      }
    });
  };

  const fetchFeedBack = async (credential) => {
    setLoadingModal(true);
    hitAPI(FEEDBACK_ENDPOINT, 'POST', credential).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.status === 200) {
          setFeedbackList(response.result);
        } else {
          setResultFeedback(true, response.messages);
        }
      } else {
        history.replace('/session-expired');
      }
      setLoadingModal(false);
    });
  };

  const fetchCategory = () => {
    setAuthState({ categoryList: [] });
    if (db) {
      let tenantId = '';
      let credential = {};
      if (getSetting() && getSetting().tenantId) {
        credential = getCredential();
        tenantId = getSetting().tenantId;
      } else {
        const tenantToken = JSON.parse(
          localStorage.getItem(localStorageNames.TENANT_ID),
        );

        tenantId = tenantToken.uuid_tenant;
        credential = {
          ...getCredential(),
          token: tenantToken.tenant_token,
          uuid: tenantId,
        };
      }

      db.get(tenantId, (error, doc) => {
        if (doc) {
          setAuthState({ categoryList: doc.result });
          return true;
        }

        hitApiCategory(tenantId, credential);
      });
    }
  };

  const hitApiCategory = (tenantId, credential) => {
    setLoadingModal(true);
    hitAPI(CATEGORY_ENDPOINT, 'POST', credential).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.status === 200) {
          setAuthState({ categoryList: response.result });
          if (response.result.length > 0) {
            const dataCategory = {
              _id: tenantId,
              result: response.result,
            };
            db.put(dataCategory);
          } else {
            setLoadingModal(false);
          }
        } else {
          setLoadingModal(false);
        }
      } else {
        history.replace('/session-expired');
      }
    });
  };

  const fetchProductTenant = async (data) => {
    setAuthState({
      productTenant: null,
      uuidProductCategory: data.uuid_product_category,
    });
    if (db) {
      let tenantId = '';
      let credential = {};
      if (getSetting() && getSetting().tenantId) {
        credential = getCredential();
        tenantId = getSetting().tenantId;
      } else {
        const tenantToken = JSON.parse(
          localStorage.getItem(localStorageNames.TENANT_ID),
        );

        tenantId = tenantToken.uuid_tenant;
        credential = {
          ...getCredential(),
          token: tenantToken.tenant_token,
          uuid: tenantId,
        };
      }

      db.get(`product-${data.uuid_product_category}`, (error, doc) => {
        //jika document kosong maka hit api product
        if (doc) {
          setAuthState({ productTenant: doc.result });
          return true;
        }
        hitApiProduct(tenantId, credential, data);
      });
    }
  };

  const hitApiProduct = (tenantId, credential, data) => {
    if (!state.loadingModal) {
      setLoadingModal(true);
    }
    hitAPI(PRODUCT_TENANT_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            let productTenant = {
              uuidCategory: data.uuid_product_category,
              products: response.result,
            };

            const dataProduct = {
              _id: `product-${data.uuid_product_category}`,
              result: {
                uuidCategory: data.uuid_product_category,
                products: response.result,
              },
            };
            db.put(dataProduct);

            setAuthState({ productTenant: productTenant });
            setLoadingModal(false);
          } else {
            setAuthState({ productTenant: null });
            setLoadingModal(false);
          }
        } else {
          history.replace('/session-expired');
        }
      },
    );
  };

  const fetchDetailProductTenant = async (uuidProductId) => {
    setAuthState({ detailProductTenant: null });
    if (db) {
      let tenantId = '';
      let credential = {};
      if (getSetting() && getSetting().tenantId) {
        credential = getCredential();
        tenantId = getSetting().tenantId;
      } else {
        const tenantToken = JSON.parse(
          localStorage.getItem(localStorageNames.TENANT_ID),
        );

        tenantId = tenantToken.uuid_tenant;
        credential = {
          ...getCredential(),
          token: tenantToken.tenant_token,
          uuid: tenantId,
        };
      }

      db.get(`detailProduct-${uuidProductId}`, (error, doc) => {
        //jika document kosong maka hit api product
        if (doc) {
          setAuthState({ detailProductTenant: doc.result });
          return true;
        }
        hitApiDetailProduct(credential, {
          uuid_product: uuidProductId,
          uuid_product_category: getAuthState('uuidProductCategory'),
        });
      });
    }
  };

  const hitApiDetailProduct = (credential, data) => {
    if (!state.loadingModal) {
      setLoadingModal(true);
    }
    hitAPI(PRODUCT_DETAIL_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            const dataProduct = {
              _id: `detailProduct-${data.uuid_product}`,
              result: response.result,
            };
            db.put(dataProduct);

            setAuthState({ detailProductTenant: response.result });
            setLoadingModal(false);
          } else {
            setAuthState({ detailProductTenant: null });
            setLoadingModal(false);
          }
        } else {
          history.replace('/session-expired');
        }
      },
    );
  };

  const sendFeedback = async (data) => {
    setLoadingModal(true);
    setResultFeedback(false, '');

    const SEND_FEEDBACK_ENDPOINT =
      process.env[`REACT_APP_CREATE_FEEDBACK_${process.env.REACT_APP_ENV}`];
    let credential = getCredential() || {};

    hitAPI(SEND_FEEDBACK_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 200) {
            setLoadingModal(false);
            setResultFeedback(false, t('feedbackThanks'));
          } else {
            let messages = errorMessages(response.messages);
            setResultFeedback(true, messages ? messages : t('errorMessage'));
            setLoadingModal(false);
          }
        } else {
          history.replace('/session-expired');
        }
      },
    );
  };

  const fetchSuggestion = (data) => {
    setAuthState({ productTenant: null });
    setLoadingModal(true);
    setResultFeedback(false, '');

    let credential = getCredential();
    hitAPI(SUGGESTION_ENDPOINT, 'POST', credential, data).then((response) => {
      if (response !== undefined && response !== null) {
        if (response.status === 200) {
          const newDataProduct = {
            uuidCategory: '-',
            products: response.result.products,
            categoryName : response.result.category_name
          };
          setAuthState({ productTenant: newDataProduct });
        }
        setLoadingModal(false);
      }
    });
  };

  const fetchStripe = async (data) => {
    setLoadingModal(true);
    let credential = getCredential();
    hitAPI(STRIPE_PAYMENT_ENDPOINT, 'POST', credential, data).then(
      (response) => {
        if (response !== undefined && response !== null) {
          if (response.status === 401) {
            setLoadingModal(false);
            history.replace('/session-expired');
          } else if (response.status === 200) {
            setLoadingModal(false);
            localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
            setAuthState({
              clientSecreet: response.result.client_secret,
            });
          } else {
            let messages = errorMessages(response.messages);
            toastError(messages);
            setLoadingModal(false);
          }
        }
      },
    );
  };

  const handleConfirmClosedModal = () => {
    getSetting() && getSetting().isDisplayLogin
      ? history.replace('/login')
      : history.replace('/invalid-token');
    setAuthState({ showClosedModal: false });
  };

  const onClickCategoryForTenant = (category, index) => {
    setAuthState({
      categoryForTenant: {
        category: category,
        index: index,
      },
    });
  };

  // =================== HANYA UNTUK DEV PANEL ==============

  const setOrderMethod = (value) => {
    let settings = getSetting() || {};
    settings.orderMethod = value;

    setLocalStorage(localStorageNames.SETTING, JSON.stringify(settings));
  };

  // =================== HANYA UNTUK DEV PANEL ==============

  const onIdle = () => {
    localStorage.removeItem(localStorageNames.CART_ITEMS);
    if (history.location.pathname !== '/order-category' && getLocalStorage(localStorageNames.KIOSK) === '1') {
      window.location.href = urlParams;
    }
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 120000,
  })

  useEffect(() => {
    // JIKA URL PENDEK, CEK AUTENTIKASINYA, USE EFFECT INI BERJALAN TERUS KARENA TANPA '[]'
    if (!history.location.search) {
      isAuthenticated();
    }
    
    getRemainingTime();
  });

  const onSWUpdate = (registration) => {
    setAuthState({ showReload: true });
    setAuthState({ waitingWorker: registration.waiting });
  };

  const reloadPage = () => {
    getAuthState('waitingWorker').postMessage({ type: 'SKIP_WAITING' });
    setAuthState({
      showReload: true,
    });
    window.location.href = urlParams;
  };

  useEffect(() => {
    if (getLocalStorage(localStorageNames.KIOSK) === '1') {
      serviceWorker.register({ onUpdate: onSWUpdate });
    } else {
      serviceWorker.unregister();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    willMount = false;
    if (history.location.search) {
      history.replace('/splash');
    }
    // eslint-disable-next-line
  }, []);

  // KARENA HOOKS TIDAK PUNYA FUNGSI PENGGANTI "componentWillMount" MAKA DILAKUKAN MANUAL
  if (willMount) {
    if (history.location.search) {
      clearAllStorage();
      deleteDb();
      saveImage();
      dumpToken();
      urlParams = history.location.search;
    } else {
      // JIKA PENDEK, LIHAT STORAGE, KEMUDIAN REDIRECT SESUAI DENGAN STATENYA
      setTimeout(() => {
        if (!localStorage.getItem(localStorageNames.USER)) {
          if (getSetting() && getSetting().isDisplayLogin) {
            history.replace('/login');
          } else {
            setUserLocalStorage('guest');
            history.replace('/order-category');
          }
        } else if (!localStorage.getItem(localStorageNames.ORDERCATEGORY)) {
          setAuthState({ cancelLoadPage: true });
          history.replace('/order-category');
        } else {
          if (getOrderMethod() === 'tenant_order') {
            getLocalStorage(localStorageNames.KIOSK) === '1'
              ? history.replace('/order-category')
              : history.replace('/tenant-page');
          } else {
            history.replace('/menu-catalogue');
          }
        }
      }, 200);
    }
  } else {
    if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
      // console.log('willMount false');
    }
  }

  return (
    <AuthContext.Provider
      value={{
        getAuthState,
        setAuthState,
        getOutletInfo,
        getOutletName,
        getOutletId,
        clearAllStorage,
        getTableName,
        getSalesType,
        getActiveSalesType,
        getCurrentSalesType,
        getCategoryList,
        getTenantList,
        getProductList,
        getFeedbackList,
        getCredential,
        hitAPI,
        isOpen,
        setOpen,
        fetchProducts,
        fetchFeedBack,
        sendFeedback,
        fetchSuggestion,
        getSplashUrl,
        getLogoUrl,
        getSetting,
        getColorApp,
        getPaymentMethod,
        getUserPhone,
        setOrderMethod,
        getOrderMethod,
        fetchStripe,
        setCredential,
        fetchProductTenant,
        getProductByCategory,
        fetchCategory,
        onClickCategoryForTenant,
        fetchDetailProductTenant,
        fetchTenants
      }}>
      <Snackbar
        open={getAuthState('showReload')}
        message="A new version is available!"
        onClick={reloadPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button size="small" onClick={reloadPage} style={{ color: 'white' }}>
            Reload
          </Button>
        }
      />
      <ToggleDrawer
        isBigScreen={bigSizeScreen}
        tableName={getTableName()}
        logoUrl={logoUrl}
        onClickCategoryForTenant={(category, index) =>
          onClickCategoryForTenant(category, index)
        }
      />
      <RealmProvider>
        <CartProvider>
          {children}
          {process.env.REACT_APP_ENV !== 'PRODUCTION' && <DevelopmentPanel />}
        </CartProvider>
      </RealmProvider>
      {getAuthState('showClosedModal') && (
        <ReOutletClosed closeModal={() => handleConfirmClosedModal()} />
      )}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (value == null) {
    throw new Error('useAuthContext() called outside of a Provider?');
  }
  return value;
};

export default AuthProvider;
