import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'styles/theme';
import { convertCurrency } from 'functions/convertNumber';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import { useGeneralContext } from 'context/GeneralContext';
import { useAuthContext } from 'context/AuthContext';
import { useTranslation } from 'react-i18next';
import localStorageNames from 'data/localStorageNames';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: 'inherit',
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  itemBody: {
    margin: `${theme.spacing(1)}px 0`,
    marginBottom: theme.spacing(-3),
  },
  nominal: {
    maxWidth: 160,
    marginLeft: theme.spacing(2),
  },
  formControl: {
    paddingBottom: theme.spacing(2),
    width: 'inherit',
  },
}));

const OptionalItem = ({
  variantItem,
  checkItem,
  max,
  getColorApp,
  isKiosK,
}) => {
  const [value, setValue] = useState('');
  const { getSellBandId } = useGeneralContext();
  const classes = useStyles();

  const handleChange = (event) => {
    checkItem(event.target.value);
    setValue(event.target.value);
  };

  const CustomRadio = withStyles({
    root: {
      color: getColorApp()?.primaryFontColor,
      '&$checked': {
        color: getColorApp()?.primaryFontColor,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Box display="flex" width={1}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}>
          {Array.isArray(variantItem) &&
            variantItem.map((item, index) => (
              <Box
                display="flex"
                width={1}
                flexDirection="row"
                className={classes.itemBody}>
                <Box display="flex" style={{ width: theme.spacing(7) }}>
                  <FormControlLabel
                    value={index.toString()}
                    control={<CustomRadio />}
                  />
                </Box>
                <Box
                  display="flex"
                  flexGrow={1}
                  whiteSpace="normal"
                  alignSelf="center"
                  style={{ marginRight: theme.spacing(1) }}>
                  <Box
                    fontSize={
                      isKiosK === '1'
                        ? theme.bodyFontSize
                        : theme.typography.fontSize
                    }
                    className={classes.textWrapper}>
                    {item.plu_name}
                  </Box>
                </Box>
                {getPriceBySellBand(item, getSellBandId()) !== 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    className={classes.nominal}>
                    <Box
                      fontSize={
                        isKiosK === '1'
                          ? theme.bodyFontSize
                          : theme.typography.fontSize
                      }
                      className={classes.textWrapper}>
                      {convertCurrency(
                        getPriceBySellBand(item, getSellBandId()),
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const ReVariantAccordion = ({
  qtyProduct,
  variantItem,
  checkItem,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getColorApp } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const customStyle = makeStyles({
    accordionActive: {
      backgroundColor: getColorApp()?.accordionHeaderColor,
      color: getColorApp()?.primaryFontColor,
    },
    accordionSuccess: {
      backgroundColor: getColorApp()?.accordionSuccessColor,
      color: getColorApp()?.secondaryFontColor,
    },
  });
  const accordionClasses = customStyle();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const handleExpand = (event, index) => {
    setExpandedPanel(index === expandedPanel ? null : index);
  };

  const accordionStyle = (index, prepItem = [], min) => {
    let checkedItem = prepItem.filter((x) => x.checked);
    let qtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      qtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    if (qtySelected >= min) {
      return accordionClasses.accordionSuccess;
    } else {
      return accordionClasses.accordionActive;
    }
  };

  return (
    <Grid>
      <Accordion
        key={'test id'}
        className={accordionStyle(0, variantItem, 1)}
        expanded={expandedPanel === 0}
        onChange={(event) => {
          handleExpand(event, 0);
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIcon: classes.expandIcon,
            root: classes.headingTitle,
            content: classes.headingContent,
          }}>
          <Box
            fontSize={
              isKiosK === '1' ? theme.bodyFontSize : theme.typography.fontSize
            }
            className={classes.textWrapper}>
            {t('variantName')}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          style={{
            backgroundColor: getColorApp()?.backgroundColor,
            color: getColorApp()?.primaryFontColor,
          }}>
          <Grid container direction="column">
            <OptionalItem
              qtyProduct={1}
              variantItem={variantItem}
              checkItem={(itemIndex) => checkItem(0, itemIndex)}
              max={1}
              getColorApp={getColorApp}
              isKiosK={isKiosK}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ReVariantAccordion;
