import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';
import { Cancel } from '@material-ui/icons';
import { useAuthContext } from 'context/AuthContext';
import { useGeneralContext } from 'context/GeneralContext';
import localStorageNames from 'data/localStorageNames';

const useStyles = makeStyles({
  paper: {
    width: `95% !important`,
    marginLeft: 0,
    marginRight: 0,
    overflowY: 'visible',
  },
  paperPwa: {
    width: `70% !important`,
    marginLeft: 0,
    marginRight: 0,
    overflowY: 'visible',
  },
  modalFullScreen: {
    width: '100% !important',
  },
  limitSize: {
    maxWidth: `${theme.containerWidth}px !important`,
  },
  paperWidthFalse: {
    maxWidth: '100%',
  },
  modalTitle: {
    textAlign: 'center',
    '& h2': {
      fontWeight: 'bold',
    },
  },
  marginCloseButton: {
    paddingTop: theme.spacing(5),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const ReDialog = ({
  closeModal,
  title,
  titleStyle,
  content,
  fullScreen,
  floatingTitle,
  scrollType,
  useCloseButton,
  dialogStyle,
  contentStyle,
  customTitleStyle,
  disableBackdropClick,
  keyboardVisibility
}) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const FloatingBox = ({ children }) => (
    <>{floatingTitle ? <Box boxShadow={3}>{children}</Box> : children}</>
  );

  return (
    <Dialog
      style={{
        height: (keyboardVisibility && isKiosK === '1') && '77vh'
      }}
      open={true}
      onClose={closeModal}
      scroll={scrollType || 'body'}
      maxWidth={false}
      fullScreen={fullScreen}
      className={dialogStyle}
      fullWidth
      disableBackdropClick={disableBackdropClick}
      classes={{
        paper: `${isKiosK === '1' ? classes.paperPwa : classes.paper} 
          ${fullScreen ? classes.modalFullScreen : ''} 
          ${isKiosK !== '1' ? classes.limitSize : ''}`,
          paperWidthFalse: classes.paperWidthFalse,
      }}>
      {useCloseButton && (
        <Box className={classes.closeButton}>
          <ButtonBase onClick={closeModal}>
            <Cancel fontSize="large" color="primary" />
          </ButtonBase>
        </Box>
      )}
      <FloatingBox>
        <DialogTitle
          style={{
            color: getColorApp()?.secondaryFontColor,
            ...customTitleStyle,
          }}
          className={`${classes.modalTitle} ${titleStyle} ${
            useCloseButton && classes.marginCloseButton
          }`}>
          <Box fontSize={isKiosK === '1' ? theme.spacing(7) : theme.spacing(5)}>
            {title}
          </Box>
        </DialogTitle>
      </FloatingBox>
      <DialogContent
        style={{ paddingBottom: theme.spacing(10), ...contentStyle }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default ReDialog;
