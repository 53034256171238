import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import theme from 'styles/theme';
import OrderedPackage from './OrderPackage';
import OrderedPrepItem from './OrderPrepItem';
import { useGeneralContext } from 'context/GeneralContext';
import localStorageNames from 'data/localStorageNames';

const DetailOrder = ({
  classes,
  t,
  getSellBandId,
  idItem,
  uuidProduct,
  timeStampProduct,
  prepGroup = [],
  additionalRequest = [],
  subProduct = [],
  changePrepItemQuantity,
  changeRealmPrepItemQuantity,
  qtyProduct,
  isNormalOrder,
  isPaymentOrder,
  getColorApp,
}) => {
  const [expandModifier, setExpandModifier] = useState(false);
  const { getLocalStorage } = useGeneralContext();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const checkAdditionalRequest = () => {
    return (
      Array.isArray(additionalRequest) &&
      additionalRequest !== null &&
      additionalRequest.length > 0
    );
  };

  const checkSubProduct = () => {
    return (
      Array.isArray(subProduct) && subProduct !== null && subProduct.length > 0
    );
  };

  const checkPrepGroup = () => {
    return (
      Array.isArray(prepGroup) && prepGroup !== null && prepGroup.length > 0
    );
  };

  const checkValue = () => {
    return checkAdditionalRequest() || checkSubProduct() || checkPrepGroup();
  };

  const showDetail = () => {
    setTimeout(() => {
      setExpandModifier(!expandModifier ? true : false);
    }, 400);
  };

  return (
    <Box>
      {checkValue() && (
        <Typography
          component="button"
          variant="caption"
          onClick={() => {
            showDetail();
          }}
          style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 20 }}
          className={`${classes.modifierText} ${classes.expandButtonModifier}`}>
          {expandModifier ? `${t('closeDetail')}` : `${t('showDetail')}...`}
        </Typography>
      )}

      {expandModifier && (
        <>
          {checkPrepGroup() && (
            <OrderedPrepItem
              classes={classes}
              getSellBandId={getSellBandId}
              idItem={idItem}
              uuidProduct={uuidProduct}
              timeStampProduct={timeStampProduct}
              prepItems={prepGroup}
              changePrepItemQuantity={changePrepItemQuantity}
              changeRealmPrepItemQuantity={changeRealmPrepItemQuantity}
              qtyProduct={qtyProduct}
              isNormalOrder={isNormalOrder}
              isPaymentOrder={isPaymentOrder}
              getColorApp={getColorApp}
            />
          )}

          {checkSubProduct() && (
            <OrderedPackage
              classes={classes}
              subProduct={subProduct}
              qtyProduct={qtyProduct}
              getColorApp={getColorApp}
            />
          )}

          {checkAdditionalRequest() && (
            <Grid item style={{ marginBottom: theme.spacing(2) }}>
              {Array.isArray(additionalRequest) &&
                additionalRequest.map((note, index) => (
                  <Box>
                    <Typography
                      variant="caption"
                      className={`${classes.modifierText}`}
                      style={{ color: getColorApp()?.primaryFontColor, fontSize: isKiosK === '1' && 18 }}
                    >
                      {`* ${note}`}
                    </Typography>
                  </Box>
                ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default DetailOrder;
