import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import localStorageNames from 'data/localStorageNames';
import { useCartContext } from 'context/CartContext';
import { useAuthContext } from 'context/AuthContext';
import { useGeneralContext } from 'context/GeneralContext';
import Button from 'components/Button';
import { ErrorOutlineRounded } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import LoadingModal from 'components/LoadingModal';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  customButton: {
    marginTop: 20,
    fontWeight: 'bold',
    width: '80%',
  },
  confirmText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});

const ShowCountdown = (props) => {
  const [countdown, setCountdown] = useState(props.seconds);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  if(countdown < 0){
    props.handleClose();
  }

  return <div>00:00:{countdown < 10 ? `0${countdown}` : countdown}</div>;
};

const QrCodeDialog = ({ closeModal, data = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getSetting, getColorApp } =
    useAuthContext();
  const { clearCart } = useCartContext();
  const { getLocalStorage } = useGeneralContext();
  const [open, setOpen] = useState(false);
  const [tableName, setTableName] = useState(null);
  let timer;
  const [isLoading, setLoading] = useState(false);
  const [stylePrint, setStylePrint] = useState(false);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);
  const history = useHistory();

  const handleIfCloseTab = () => {
    window.addEventListener('beforeunload', alertUser);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleClose = async () => {
    setOpen(false);
    closeModal();
    history.replace('/order-category');
  };

  const handleBackHome = () => {
    setOpen(true);
  };

  const handleAfterPrint = () => {
    setStylePrint(false);
    history.replace('/order-category');
  };

  const handleBeforePrint = React.useCallback(() => {
    console.log('`onBeforePrint` called'); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('`onBeforeGetContent` called'); // tslint:disable-line no-console
    setLoading(true);
    setStylePrint(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setTimeout(() => {
        setLoading(false);
        // setStylePrint(false);s
        resolve();
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Print-qr-'+data,
    removeAfterPrint: true,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });
  
  useEffect(() => {
    const setting = JSON.parse(localStorage.getItem(localStorageNames.SETTING));
    if (setting.tableName) {
      setTableName(setting.tableName);
    }
  }, []);

  useEffect(() => {
    handleIfCloseTab();
    clearCart();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const AlertDialog = () => {
    return (
      <ReDialog
        title={
          <ErrorOutlineRounded
            style={{ color: theme.palette.success.main, fontSize: '3rem' }}
          />
        }
        titleStyle={classes.titleStyle}
        customTitleStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        contentStyle={{ backgroundColor: getColorApp()?.backgroundColor, color: getColorApp()?.primaryFontColor }}
        content={
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Typography className={classes.confirmText} style={{ color: getColorApp()?.primaryFontColor }}>
                {t('messageLeaveQr')}
              </Typography>
            </Grid>
            <Grid container direction="row">
              <Grid item style={{ width: '50%' }}>
                <Button
                  onClick={() => setOpen(false)}
                  className={classes.customButton}
                  style={{left: 'calc(50% - 80%/2)'}}
                  size="large"
                  type="cancel"
                  title={`${t('cancel')}`}
                />
              </Grid>
              <Grid item style={{ width: '50%' }}>
                <Button
                  onClick={handleClose}
                  className={classes.customButton}
                  style={{left: 'calc(50% - 80%/2)'}}
                  size="large"
                  type="confirm"
                  title={`${t('confirm')}`}
                />
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  };

  return (
    <ReDialog
      fullScreen={true}
      closeModal={closeModal}
      useCloseButton={false}
      scrollType={'paper'}
      contentStyle={{ paddingTop: theme.spacing(10), backgroundColor: 'white', overflow: 'hidden' }}
      content={
        <Box
          display="flex"
          flex="4"
          flexDirection="column"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
          }}>
          {open && (<AlertDialog />)}
          <Prompt
            when={true}
            message={(location, action) => {
              if (action === 'POP') {
                setOpen(true);
                return false;
              }
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" style={{ marginBottom: theme.spacing(4) }}>
              {tableName && `TABLE : ${tableName}`}
            </Typography>

            {/* <Typography variant="subtitle1" align="center">
              {
                (getSetting() && getSetting().qrcodeTitlePage) ?
                  getSetting().qrcodeTitlePage :
                  t('qrcodeTitle')
              }
            </Typography> */}

            <Box
              ref={componentRef}
              display="flex"
              flexDirection="column"
              style={{
                alignItems: 'center',
                height: 'auto',
              }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{
                  fontSize: stylePrint ? 14 : 20,
                  marginBottom: theme.spacing(4),
                  marginTop: theme.spacing(1),
                  width: stylePrint && '50%'
                }}>
                {getSetting() && getSetting().qrcodeTitlePage
                  ? getSetting().qrcodeTitlePage
                  : t('qrcodeTitle')}
              </Typography>
              
              <QRCode value={data} size={stylePrint ? 120 : 300} />

              <Typography
                variant={stylePrint ? 'h5' : 'h4'}
                style={{ marginTop: theme.spacing(4) }}>
                {data}
              </Typography>

              {stylePrint && (
                <Typography
                  variant="h4"
                  style={{
                    fontSize: stylePrint ? 14 : 30,
                    marginTop: theme.spacing(4),
                  }}>
                  ORDER TIME: {moment().format('DD/MM/YYYY HH:mm')}
                </Typography>
              )}
            </Box>

            <Box
              style={{
                display: stylePrint ? 'none' : 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme.spacing(8),
              }}>
              <Button
                onClick={() => handleBackHome()}
                className={classes.customButton}
                size="small"
                title={`${t('paymentBack')}`}
                style={{
                  marginTop: theme.spacing(10),
                  width: 150,
                  backgroundColor: getColorApp()?.primaryThemeColor, 
                  color: getColorApp()?.primaryFontColor,
                }}
              />
              { isKiosK === '1' && (
                <Button
                  onClick={() => handlePrint() }
                  size="small"
                  title="Print"
                  style={{
                    color: getColorApp()?.secondaryFontColor,
                    backgroundColor: getColorApp()?.secondaryThemeColor, 
                    marginTop: theme.spacing(10),
                    width: 150,
                  }}
                />
                )
              }  
            </Box>

            { isKiosK === '1' && (
              <Typography variant="h4" style={{ marginTop: theme.spacing(2), display: stylePrint && 'none'  }}>
                <ShowCountdown seconds={60} handleClose={handleClose}/>
              </Typography>
              )
            }  
          </Box>
          {isLoading && <LoadingModal />}
        </Box>
      }
    />
  );
};

export default QrCodeDialog;
