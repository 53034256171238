import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Checkbox,
  Box,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'styles/theme';
import { convertCurrency } from 'functions/convertNumber';
import { useTranslation } from 'react-i18next';
import ReButtonQuantity from './ReButtonQuantity';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import { useGeneralContext } from 'context/GeneralContext';
import { useAuthContext } from 'context/AuthContext';
import localStorageNames from 'data/localStorageNames';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    color: 'inherit',
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  widthButtonQty: {
    width: 125,
    alignSelf: 'center',
  },
  widthCurrency: {
    width: 160,
    marginLeft: theme.spacing(2),
  },
  borderCheckbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const Accordion = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const OptionalItem = ({
  qtyProduct,
  prepItem,
  checkItem,
  changeQuantity,
  max,
  isOrderable,
  isModifier,
  getColorApp,
  isKiosK,
}) => {
  const classes = useStyles();
  const { getSellBandId } = useGeneralContext();

  const disableLogic = (prepItem, item) => {
    let checkedItem = prepItem.filter((x) => x.checked);

    let totalQtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      totalQtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    if (item.checked) {
      return false;
    } else if (!item.checked && totalQtySelected >= max) {
      return true;
    }
  };

  const actionQtyButton = (index, value) => {
    changeQuantity(index, value);
  };

  const actionChecboxClick = (index) => {
    checkItem(index);
    if (!isModifier) {
      changeQuantity(index, 1);
    }
  };

  const countOtherPrep = (prepItem, uuid_product) => {
    let checkedItem = prepItem.filter(
      (x) => x.checked && x.uuid_product !== uuid_product,
    );
    let totalQtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      totalQtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    return totalQtySelected;
  };

  return (
    <>
      {Array.isArray(prepItem) &&
        prepItem.map((item, index) => (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            style={{ margin: `${theme.spacing(1)}px 0` }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              style={{ width: 'inherit' }}>
              <Box
                display="flex"
                flexDirection="row"
                style={{ justifyContent: 'start' }}>
                {isOrderable && (
                  <Checkbox
                    onClick={() =>
                      isOrderable &&
                      !disableLogic(prepItem, item) &&
                      actionChecboxClick(index, item.quantity)
                    }
                    checked={item.checked || false}
                    disabled={disableLogic(prepItem, item)}
                    className={classes.borderCheckbox}
                    style={{
                      color: getColorApp()?.primaryFontColor,
                      fontSize:
                        isKiosK === '1'
                          ? theme.bodyFontSize
                          : theme.typography.fontSize,
                    }}
                  />
                )}
                <Box
                  component="div"
                  whiteSpace="normal"
                  alignSelf="center"
                  style={{
                    marginRight: theme.spacing(1),
                    fontSize:
                      isKiosK === '1'
                        ? theme.bodyFontSize
                        : theme.typography.fontSize,
                  }}>
                  {isModifier ? item.message : item.plu_name}
                </Box>
              </Box>
            </Box>
            {item.checked && item.use_quantity && (
              <Box className={classes.widthButtonQty}>
                <ReButtonQuantity
                  onChangeValue={(value) => actionQtyButton(index, value)}
                  max={max - countOtherPrep(prepItem, item.uuid_product) - 1}
                  qtyProduct={qtyProduct}
                />
              </Box>
            )}
            <Box
              display="flex"
              alignSelf="center"
              justifyContent="flex-end"
              className={classes.widthCurrency}>
              {getPriceBySellBand(item, getSellBandId()) !== 0 && (
                <Box
                  fontSize={
                    isKiosK === '1'
                      ? theme.bodyFontSize
                      : theme.typography.fontSize
                  }
                  className={classes.textWrapper}>
                  {convertCurrency(
                    getPriceBySellBand(item, getSellBandId()) *
                      (item.quantity > 1 ? item.quantity : 1) *
                      (item.checked ? qtyProduct : 1),
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

const RePrepItemAccordion = ({
  qtyProduct,
  prepGroup,
  checkItem,
  changeQuantity,
  isOrderable,
  isModifier,
}) => {
  const classes = useStyles();
  const { getColorApp } = useAuthContext();
  const { getLocalStorage } = useGeneralContext();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { t } = useTranslation();
  const customStyle = makeStyles({
    accordionActive: {
      backgroundColor: getColorApp()?.accordionHeaderColor,
      color: getColorApp()?.primaryFontColor,
    },
    accordionSuccess: {
      backgroundColor: getColorApp()?.accordionSuccessColor,
      color: getColorApp()?.secondaryFontColor,
    },
  });
  const accordionClasses = customStyle();
  const isKiosK = getLocalStorage(localStorageNames.KIOSK);

  const handleExpand = (event, index) => {
    setExpandedPanel(index === expandedPanel ? null : index);
  };

  const accordionStyle = (index, prepItem = [], min) => {
    let checkedItem = prepItem.filter((x) => x.checked);
    let qtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      qtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    if (qtySelected >= min) {
      return accordionClasses.accordionSuccess;
    } else {
      return accordionClasses.accordionActive;
    }
  };

  return (
    <Grid>
      {Array.isArray(prepGroup) &&
        prepGroup.map((group, index) => (
          <Accordion
            key={group.uuid_group}
            className={accordionStyle(
              index,
              isModifier ? group.modifiers : group.prep_item,
              group.choose.min,
            )}
            expanded={expandedPanel === index}
            onChange={(event) => {
              handleExpand(event, index);
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                expandIcon: classes.expandIcon,
                root: classes.headingTitle,
                content: classes.headingContent,
              }}>
              <Box
                fontSize={
                  isKiosK === '1'
                    ? theme.bodyFontSize
                    : theme.typography.fontSize
                }
                className={classes.textWrapper}>
                {group.group_name}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              style={{
                backgroundColor: getColorApp()?.backgroundColor,
                color: getColorApp()?.primaryFontColor,
              }}>
              <Grid container direction="column">
                {isModifier ? (
                  <Grid item>
                    <Typography variant={isKiosK === '1' ? 'body1' : 'caption'}>
                      {`${t('minChoose')} = ${group.choose.min}, ${t(
                        'maxChoose',
                      )} = ${group.choose.max}`}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant={isKiosK === '1' ? 'body1' : 'caption'}>
                      {`${t('minChoose')} = ${group.choose.min}, ${t(
                        'maxChoose',
                      )} = ${group.choose.max} (${t('perproduct')})`}
                    </Typography>
                  </Grid>
                )}
                {isModifier &&
                  group.modifiers &&
                  group.modifiers.length > 0 && (
                    <OptionalItem
                      qtyProduct={qtyProduct}
                      prepItem={group.modifiers}
                      checkItem={(itemIndex) => checkItem(index, itemIndex)}
                      max={group.choose.max}
                      isOrderable={isOrderable}
                      isModifier={isModifier}
                      getColorApp={getColorApp}
                      isKiosK={isKiosK}
                    />
                  )}
                {!isModifier &&
                  group.prep_item &&
                  group.prep_item.length > 0 && (
                    <OptionalItem
                      qtyProduct={qtyProduct}
                      prepItem={group.prep_item}
                      checkItem={(itemIndex) => checkItem(index, itemIndex)}
                      changeQuantity={(itemIndex, qty) =>
                        changeQuantity(index, itemIndex, qty)
                      }
                      max={isModifier ? 0 : group.choose.max}
                      isOrderable={isOrderable}
                      getColorApp={getColorApp}
                      isKiosK={isKiosK}
                    />
                  )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </Grid>
  );
};

export default RePrepItemAccordion;
